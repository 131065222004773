// Fonts

@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:300,900&display=swap');

// Helpers

$vendors: ('-webkit-','-moz-','-ms-','-o-','');

@mixin vendor($key, $val...) {
  // Low level mixin for prepending a property with known vendor prefixes
  @each $vendor in $vendors {
    #{$vendor}#{$key}: $val; 
  }
}

@mixin box-sizing($val...) {
  @include vendor(box-sizing, $val);
}

@mixin mobile() {
  @media screen and (max-width: 500px) {
    @content;
  }
}

// Resets

* { @include box-sizing(border-box); }
img { border: 0; margin: 0; padding: 0; width: 100%; display: block; }
.not-100-percent { width: auto; }
ul, ol { list-style: none; margin: 0; padding: 0; }
html {
  font-size: 100%;
  //  text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, html { margin: 0; padding: 0; }
address { font-style: normal; }
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display:block;
}
iframe { border: 0; }
a { color: inherit; }

// Vars

$vendors: ('-webkit-','-moz-','-ms-','-o-','');

$light: #e8edf3;
$dark: #22264b;
$yellow: #e6cf8b;
$red: #b56969;

$neutral: #cccccc;

// General

body {
  font-size: 1.5vw;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  line-height: 1.4;
  margin: 2em;
  background-color: $light;
  color: $dark;
  @include mobile {
    font-size: 3vw;
  }
}

strong, b {
  font-weight: 900;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  color: $red;
}
h2 {
  font-size: 1.2em;
}

a {
  text-decoration: none;
  border-bottom: 1px solid rgba($dark, 0.2);
  // border-bottom: 1px solid currentcolor;
  @include vendor(transition, 0.2s);
  &:hover {
    border-bottom: 1px solid rgba($dark, 1);
  }
}

hr {
  border-top: 1px solid $dark;
  margin: 4em 0;
}

img[alt^=Book] { max-width: 10em; width: auto; }

.center-text { text-align: center; }

.strip-margins-container > *:first-child {
  margin-top: 0;
}
.strip-margins-container > *:last-child {
  margin-bottom: 0;
}

.trunk , .sidebar {
  display: inline-block;
  vertical-align: top;
  > *:not(hr):first-child,
  > section > *:not(hr):first-child { 
    margin-top: 0; line-height: 1; 
  }
}

.trunk {
  width: 70%;
  @include mobile {
    width: 100%;
  }
}

.sidebar {
  width: 30%;
  padding-left: 2em;
  position: -webkit-sticky;
  position: sticky;
  top: 2em;
  @include mobile {
    width: 100%;
    margin-top: 2em;
  }
  h6 { font-size: 1em; margin: 1em 0; }
  * + h6 { margin-top: 2em; }
  ul {
    list-style: none;
    li {
      font-size: 0.7em;
      @include mobile {
        font-size: 1em;
      }
    }
    li + li {
      margin-top: 0.5em;
    }
  }
  ul.app-list li {
    display: inline-block;
    width: 25%;
    padding-right: 0.5em;
  }
}

figure {
  margin-left: 0; margin-right: 0;
}

blockquote {
  border-left: 1px solid $dark;
  width: 60%;
  margin: 0;
  padding-left: 2em;
}

ul {
  list-style: disc inside none;
}

.error-message {
  color: $red;
}

input[type=text], input[type=number], input[type=email], textarea, select {
  // font-family: 'Roboto Mono', monospace;
  font-family: 'Lato', sans-serif;
  font-size: inherit;
  color: inherit;
  padding: 0.2em 0.5em;
  border: 0;
  border-radius: 0;
  background-color: $dark; color: $light;
  @include vendor(appearance, none);
  &:focus {
    outline: 0;
  }
}

textarea {
  height: 5em;
}

button[type=button], button[type=submit], a.button {
  background-color: $yellow;
  color: $dark;
  font-size: 0.6em;
  text-transform: uppercase;
  padding: 0.2em 0.5em;
  border: 0;
  border-radius: 0.5em;
  cursor: pointer;
  transition: 0.3s;
  @include mobile {
    font-size: 1em;
  }
  &:hover {

    background-color: $dark;
    color: $light;
  }
  &:focus {
    outline: 0;
  }
  @include mobile {
    & + & {
      margin-left: 1em;
    }
  }
}

code {
  font-family: "Roboto Mono", monospace;
}
p code {
  padding: 0 0.5em;
  background-color: rgba($dark, 0.2);
}
pre code {
  white-space: pre-wrap;
  word-break: break-all;
}

body > header {
  h1 {
    color: $dark;
    margin-top: 0;
    margin-bottom: 2em;
    span.appname {
      color: $red;
      @include mobile {
        display: block;
      }
    }
    a { 
      text-decoration: none; border: 0; 
      &:hover {
        border: 0;
      }
    }
    span.onhover {
      color: $yellow;
      font-size: 0;
      @include vendor(transition, 0.5s);
    }
    a:hover span.onhover {
      font-size: 1em;
    }
  }
  nav {
    position: absolute;
    right: 2em; top: 2em;
    // @include mobile {
    //   display: none;
    // }
    ol {
      li {
        display: inline-block;
        vertical-align: middle;
        & + li {
          margin-left: 0.5em;
        }
        a, a:hover {
          border: 0;
        }
        img {
          width: 2.5em;
        }
      }
    }
  }
}

body > footer {
  font-size: 0.7em;
  margin-top: 8em;
  text-align: center;
}

// Home

#app-list {
  li {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    padding: 0.5em;
    text-align: center;
    a:hover img {
      @include vendor(transform, scale(1.03));
    }
    a {
      text-decoration: none;
      border: 0;
      font-size: 0.8em;
      img {
        @include vendor(transition, 0.5s);
      }
    }
  }
}

.blog-article-teaser {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0.5em;
}

// Placeholder Images

figure.placeholder-example {
  padding-left: 2em;
  border-left: 1px solid $dark;
  display: inline-block;
  width: 100%;
  img { width: auto; }
  figcaption {
    font-family: 'Roboto Mono', monospace;
    margin: 0 0 0.5em 0;
  }
}

// Color Converter

.color-type {
  display: inline-block;
  width: (100% / 5);
  vertical-align: top;
  margin-bottom: 1em;
  font-family: 'Roboto Mono', monospace;
  @include mobile {
    width: 50%;
    margin-bottom: 2em;
  }
  > p:first-child {
    margin-top: 0;
  }
  .swatch {
    display: inline-block;
    width: 5em; height: 3em;
  }
  input {
    width: 5em;
    font-family: 'Roboto Mono', monospace;
  }
}

// My IP

#my-ip .ip {
  font-family: 'Roboto Mono', monospace;
  font-size: 5em;
  margin: 0;
}

// Contact

#contact {
  input, select, textarea {
    width: 100%;
  }
  textarea {
    height: 15em;
  }
}

// Code highlight

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: $dark;
  color: $light;
}

.hljs-comment,
.hljs-quote {
  color: rgba($light, 0.4);
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-addition {
  color: $yellow;
}

.hljs-number,
.hljs-string,
.hljs-meta .hljs-meta-string,
.hljs-literal,
.hljs-doctag,
.hljs-regexp {
  color: MediumAquamarine;
}

.hljs-title,
.hljs-section,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: SteelBlue;
}

.hljs-attribute,
.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-class .hljs-title,
.hljs-type {
  color: $yellow;
}

.hljs-symbol,
.hljs-bullet,
.hljs-subst,
.hljs-meta,
.hljs-meta .hljs-keyword,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-link {
  color: $red;
}

.hljs-built_in,
.hljs-deletion {
  color: $yellow;
}

.hljs-formula {
  background: rgba($light, 0.5);
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
